import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';

import { LegacyRoute } from '../routesMiddleware';
import Consultations from './Consultations';

const route: LegacyRoute = {
  path: '/consultations',

  async action() {
    document.title = 'Consultations';
    return (
      <LayoutPage showNav selected="consultations">
        <ConsultationsStarting />
        <Consultations />
      </LayoutPage>
    );
  },
};

export default route;
