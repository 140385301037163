import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { track } from '@/actions/tracking';
import { white } from '@/theme/colors';

import Button from '../Button/Button';
import Dialog, { DialogProps } from '../Dialog';
import EmptyMessage from '../EmptyMessage';
import FAIcon from '../Icon/FAIcon';

interface InternalNetworkSharePromoDialogProps extends DialogProps {
  open: boolean;
  track: (event: string) => void;
  clickEventTrack?: string;
  showEventTrack?: string;
}

const InternalNetworkSharePromoDialog = (
  props: InternalNetworkSharePromoDialogProps
): JSX.Element => {
  const previousOpen = useRef(props.open);
  const {
    open,
    track,
    clickEventTrack = 'promo.click.internal_network.share',
    showEventTrack = 'promo.show.internal_network.share',
    ...rest
  } = props;
  const handleClick = () => {
    track(clickEventTrack);
    window.HubSpotConversations?.widget?.open();
  };

  useEffect(() => {
    if (open && showEventTrack) track(showEventTrack);
    if (!previousOpen.current && open && showEventTrack) track(showEventTrack);

    previousOpen.current = open;
  }, [open, showEventTrack, track]);

  return (
    <Dialog promo open={open} {...rest}>
      <EmptyMessage
        border={false}
        style={{ padding: 20 }}
        titleStyle={{ color: white, fontSize: 20 }}
        bodyStyle={{ color: white, fontSize: 16 }}
        icon={<FAIcon iconSet="fal" icon="sitemap" size={30} color="rgba(255, 255, 255, .52)" />}
        title="Share your internal network"
        body={
          <React.Fragment>
            Share experts with partners and other organizations on OnFrontiers.
            <br />
            Contact our support team to know more.
          </React.Fragment>
        }
        action={
          <Button
            onClick={handleClick}
            fontColor={white}
            backgroundColor="rgba(255, 255, 255, .2)"
            style={{ backgroundColor: 'transparent' }}
          >
            More Info
          </Button>
        }
      />
    </Dialog>
  );
};

export default connect(undefined, {
  track,
})(InternalNetworkSharePromoDialog);
