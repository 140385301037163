import { Button as MaterialButton } from '@mui/material';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, SubmissionError, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import { requestPasswordReset } from '@/auth/store';
import Form from '@/components/Form';
import { TextField } from '@/components/FormAdapters';
import Link from '@/components/Link';
import WizardPage from '@/components/WizardPage';
import WizardFooter from '@/components/WizardPage/WizardFooter';

const ERROR_INVALID_EMAIL = 'GraphQL Error: invalid email';

interface FormData {
  email: string;
}

function validate(values: FormData) {
  const errors: Partial<FormData> = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  return errors;
}

const errDoesNotExist = 'user does not exist';

const connector = connect(undefined, {
  requestPasswordReset,
  notify,
});

const RequestPasswordReset = (
  props: ConnectedProps<typeof connector> & InjectedFormProps<FormData>
): JSX.Element => {
  const [done, setDone] = useState(false);
  const { requestPasswordReset, notify, handleSubmit } = props;

  const handleSubmitFunc = async (values: FormData) => {
    try {
      await requestPasswordReset(values.email);
      setDone(true);
    } catch (err: any) {
      if (err && err.rawError && err.rawError[0] && err.rawError[0].message === errDoesNotExist) {
        throw new SubmissionError({
          email: 'There is no user with this email address',
        });
      } else {
        if (err instanceof Error && err.message === ERROR_INVALID_EMAIL) {
          notify('Invalid email format', 'error');
        } else {
          notify('An error occurred when trying to reset password.', 'error');
        }
      }
    }
  };

  if (done) {
    return (
      <div>
        <h3>Password reset email sent! You should receive it within a few minutes.</h3>
        <Link to="/login">
          <MaterialButton>← Return to Login</MaterialButton>
        </Link>
      </div>
    );
  }

  return (
    <WizardPage
      title="Reset your password"
      subTitle="We'll email you a link to create a new password."
    >
      <Form onSubmit={handleSubmit(handleSubmitFunc)}>
        <Field
          name="email"
          changeOnBlur={false}
          component={TextField}
          autoComplete="email"
          label="Email address"
          type="email"
          style={{ width: 300 }}
        />
        <WizardFooter nextLabel="Reset Password" />
      </Form>
    </WizardPage>
  );
};

export default connector(
  reduxForm<FormData, null>({
    form: 'requestPasswordReset',
    validate,
  })(RequestPasswordReset)
) as unknown as React.ComponentType;
