import { Fragment } from 'react';

export function truncateText(text: string, limit: number): string {
  if (!text) {
    return '';
  }
  if (text.length < limit) return text;
  return `${text.substring(0, limit)}...`;
}

export const capitalizer = (inputString: string): string =>
  inputString.charAt(0).toUpperCase() + inputString.slice(1);

export const uniqueSortedSet = (arr: (string | undefined)[]): string[] =>
  Array.from(new Set((arr || ([] as string[])).filter(Boolean))).sort() as string[];

export const joinUnique = (texts: (string | null | undefined)[], delimiter = ', '): string =>
  Array.from(new Set((texts || ([] as string[])).filter(Boolean))).join(delimiter);

export const nl2br = (string: string): JSX.Element[] =>
  string.split('\n').map((item, key) => {
    return (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    );
  });

export const formatCurrencyValue = (
  contractValueCurrency?: string | null,
  contractValue?: number | null
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contractValueCurrency ?? 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(contractValue ?? 0));
};

export const assignmentTypeDict: Record<string, string> = {
  Job: 'Job',
  Project: 'Project',
  Proposal: 'New Business Activity',
};
