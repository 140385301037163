const ERROR_CODES = {
  UNKNOWN: 'unknown.unknown',
  ADDRESS_ADDRESS_TAKEN: 'address.address_taken',
  AUTH_INVALID_CREDENTIALS: 'auth.invalid_credentials',
  AUTH_MFA_SETUP_REQUIRED: 'auth.mfa_setup_required',
  AUTH_MFA_REQUIRED: 'auth.mfa_required',
  AUTH_LOCKED_FOR_INTERVAL: 'auth.locked_for_interval',
  AUTH_OTP_INVALID: 'auth.otp_invalid',
  AUTH_PASSWORD_NOT_EQUAL: 'auth.password_not_equal',
  AUTH_PASSWORD_REUSED: 'auth.password_reused',
  AUTH_TOKEN_EXPIRED: 'auth.token_expired',
  AUTH_USER_NOT_FOUND: 'auth.user_not_found',
  CONFERENCE_HOST_CONFERENCE_NOT_FOUND: 'conference.host_conference_not_found',
  CONFERENCE_NOT_FOUND: 'conference.not_found',
  CONSULTATION_NOT_FOUND: 'consultation.not_found',
  EXPERT_BILL_RATE_TOO_HIGH: 'expert.bill_rate_too_high',
  EXPERT_REQUEST_NOT_FOUND: 'expertrequest.not_found',
  GROUP_NOT_FOUND: 'group.not_found',
  GROUP_MEMBER_NOT_FOUND: 'group.member_not_found',
  PERMISSION_NOT_ALLOWED: 'permission.not_allowed',
  PROFILE_NOT_FOUND: 'profile.not_found',
  PROJECT_NOT_FOUND: 'project.not_found',
} as const;

export type ErrorCode = (typeof ERROR_CODES)[keyof typeof ERROR_CODES];

export default ERROR_CODES;
