import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { isValidPassword } from '@/auth';
import { PasswordText } from '@/auth/components/PasswordTextField';
import { resetPassword, setPassword } from '@/auth/store';
import Button from '@/components/Button/Button';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { RootState } from '@/store';

const useStyles = makeStyles({
  form: {
    maxWidth: 350,
  },
  actions: {
    marginTop: 25,
  },
});

type FormData = {
  hasPassword: boolean;
  currentPassword: string;
  newPassword: string;
};

type FormErrors = Partial<Record<keyof FormData, string>>;

function validate(values: FormData): FormErrors {
  const errors: FormErrors = {};

  if (values.hasPassword && !values.currentPassword) {
    errors.currentPassword = 'Required';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else if (!isValidPassword(values.newPassword)) {
    errors.newPassword = 'Password does not meet the requirements';
  }

  if (values.newPassword && values.newPassword === values.currentPassword) {
    errors.newPassword = 'New password must be different from the current';
  }

  return errors;
}

interface ChangePasswordProps {
  token?: string;
  userId?: string;
  onSubmit?: (values: FormData) => void;
  successNotification?: boolean;
}

const connector = connect(
  (state: RootState, ownProps: ChangePasswordProps) => {
    const userId = ownProps.userId || state.viewer.id;
    const user = state.users[userId] || {};
    return {
      userId,
      hasPassword: user.has_password,
    };
  },
  {
    setPassword,
    resetPassword,
    notify,
  }
);

const ChangePassword = ({
  token,
  hasPassword = false,
  userId,
  onSubmit,
  successNotification = true,
  setPassword,
  resetPassword,
  notify,
}: ChangePasswordProps & ConnectedProps<typeof connector>) => {
  const s = useStyles();

  const handleFinalSubmit = useCallback(
    async (values: FormData): Promise<FormErrors | undefined> => {
      try {
        if (token) {
          await resetPassword(token, values.newPassword);
        } else {
          await setPassword(userId, values.currentPassword, values.newPassword);
        }

        if (successNotification) notify('Password updated.');

        if (onSubmit) onSubmit(values);
      } catch (e: unknown) {
        if (hasErrorCode(e, ERROR_CODES.AUTH_PASSWORD_NOT_EQUAL)) {
          return { currentPassword: 'Current password is incorrect.' };
        }

        if (hasErrorCode(e, ERROR_CODES.AUTH_PASSWORD_REUSED)) {
          return { newPassword: 'Cannot reuse the last 5 passwords.' };
        }

        if (hasErrorCode(e, ERROR_CODES.AUTH_TOKEN_EXPIRED) || (e as any).isPermissionError) {
          notify('Reset password link has expired.', 'error');
        } else {
          notify('An error occurred when trying to set the password.', 'error');
        }
      }
    },
    [notify, onSubmit, resetPassword, setPassword, successNotification, token, userId]
  );

  const initialValues = { hasPassword, currentPassword: '', newPassword: '' };

  return (
    <Form
      onSubmit={handleFinalSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit }) => {
        return (
          <form className={s.form} onSubmit={handleSubmit}>
            {hasPassword && (
              <Field
                id="currentPassword"
                changeOnBlur={false}
                component={PasswordText}
                name="currentPassword"
                label="Current Password"
                variant="outlined"
              />
            )}
            <Field
              id="newPassword"
              changeOnBlur={false}
              component={PasswordText}
              name="newPassword"
              label="New Password"
              showHelp
              variant="outlined"
              inputProps={{ autoComplete: 'new-password' }}
              current={false}
            />
            <div className={s.actions}>
              <Button type="submit" size="medium" id="updatePasswordButton">
                Update Password
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default connector(ChangePassword);
