import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';

import { presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

import { AttachmentType } from './Attachments';

const useStyles = makeStyles(() => ({
  attachmentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fileLink: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#00695C',
    textDecoration: 'none',
    marginBottom: '8px',
  },
}));

interface PublicAttachmentProps {
  attachment: AttachmentType;
  index: number;
}

const PublicAttachment = ({ attachment, index }: PublicAttachmentProps): JSX.Element => {
  const s = useStyles();
  const { graphql } = useApp();

  const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function fetchPresignedURL() {
      if (attachment.file_url) {
        const url = await presignAttachmentURL(graphql.client, attachment.file_url);
        setExternalURL(url);
      }
    }
    fetchPresignedURL();
  }, [graphql.client, attachment.file_url]);

  return (
    <div className={s.attachmentContainer}>
      <a href={externalURL} target="_blank" rel="noreferrer" className={s.fileLink}>
        {`${index + 1}. ${attachment.name}`}
      </a>
      {attachment.description && (
        <MuiTextField
          disabled
          style={{ marginTop: '0px' }}
          id="standard-disabled"
          label="Description *"
          value={attachment.description}
          variant="standard"
        />
      )}
    </div>
  );
};

export default PublicAttachment;
