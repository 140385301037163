import { useQuery } from '@apollo/client';
import { Fab as FloatingActionButton, SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';

import { gengql } from '@/__generated__';
// import { fetchProjects } from '@/actions/project';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import LongList from '@/components/LongList';
import ProjectPreview from '@/components/ProjectPreview';
import { Select } from '@/components/Select/Select';
import TeamAccountPromoButton from '@/components/TeamAccountPromo/TeamAccountPromoButton';
import { isGroupContext } from '@/core/user';
import { normalizeSpace } from '@/utils';

export const SEARCH_PROJECT_GQL: any = gengql(/* GraphQL */ `
  query searchProjects(
    $cursor: String
    $pageSize: Int!
    $openOnly: Boolean
    $memberOnly: Boolean
    $groupId: String
    $status: ExpertRequestState
    $searchTitle: String
  ) {
    searchProjects(
      after: $cursor
      first: $pageSize
      open_only: $openOnly
      member_only: $memberOnly
      group_id: $groupId
      expert_request_state: $status
      search_title: $searchTitle
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          expert_requests(state: $status) {
            id
            created_at
            name
            state
            archived
            stats {
              candidates_count(states: [matched, verified, vetting])
              calls
            }
            html_url
            permissions
          }
          members {
            id
            role
            state
            user {
              id
              name
              first_name
              last_name
              html_url
              picture_url
            }
          }
          group {
            id
            name
          }
          html_url
        }
      }
    }
  }
`);

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
];

function ExpertRequests(props: any): JSX.Element {
  const { userContext } = props;

  const [searchTitle, setSearchTitle] = useState('');
  const [status, setStatus] = useState('all');
  const [edges, setEdges] = useState<any[]>([]);
  const [pageInfo, setPageInfo] = useState({ hasNextPage: false });

  const statusValue = status === 'all' ? null : status;

  const groupId = isGroupContext(userContext) ? userContext : undefined;
  const { data, loading, fetchMore } = useQuery(SEARCH_PROJECT_GQL, {
    variables: {
      pageSize: 10,
      groupId,
      openOnly: false,
      memberOnly: false,
      status: statusValue,
      searchTitle,
    },
  });

  const handleMore = () => {
    if (!pageInfo.hasNextPage) return;

    if (!edges.length) throw new Error('last project edge not found');
    fetchMore({
      variables: {
        cursor: edges[edges.length - 1].cursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          searchProjects: {
            pageInfo: fetchMoreResult.searchProjects.pageInfo,
            edges: [
              ...previousResult.searchProjects.edges,
              ...fetchMoreResult.searchProjects.edges,
            ],
          },
        };
      },
    });
  };

  useEffect(() => {
    if (data) {
      setEdges(data.searchProjects.edges);
      setPageInfo(data.searchProjects.pageInfo);
    }
  }, [data]);

  const projects = edges.map((e: any) => e.node);

  return (
    <div>
      <ConsultationsStarting />

      <LongList
        loading={loading}
        title={projects.length > 0 || loading ? 'Expert Requests' : 'No Expert Requests Found'}
        hasNextPage={pageInfo.hasNextPage && edges.length > 0}
        onMore={handleMore}
        action={
          <Grid
            container
            spacing={2}
            sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}
          >
            <Box sx={{ width: { xs: '100%', md: 150 } }}>
              <Select
                sort={false}
                margin="dense"
                value={status}
                fullWidth
                onChange={(event: SelectChangeEvent<string>) => {
                  setStatus(normalizeSpace(event.target.value));
                }}
                options={statusOptions}
              />
            </Box>
            <Box sx={{ width: { xs: '100%', md: 300 } }}>
              <TextField
                value={searchTitle ?? ''}
                margin="none"
                fullWidth
                onChange={({ target: { value } }) => {
                  setSearchTitle(normalizeSpace(value));
                }}
                placeholder="Filter by Project or Expert Request Title"
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: 'auto' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', sm: 'flex-start' },
              }}
            >
              <TeamAccountPromoButton component={FloatingActionButton}>
                <MaterialIcon icon="add" />
              </TeamAccountPromoButton>
            </Box>
          </Grid>
        }
      >
        {projects.map((p: any) => (
          <ProjectPreview key={p.id} project={p} />
        ))}
      </LongList>
    </div>
  );
}

ExpertRequests.propTypes = {
  edges: PropTypes.array,
  pageInfo: PropTypes.object,
  fetchProjects: PropTypes.func,
};

// @ts-expect-error TS(2631) FIXME: Cannot assign to 'ExpertRequests' because it is a ... Remove this comment to see the full error message
ExpertRequests = connect((state) => {
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  const page = state.projects.default;
  return {
    ...page,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    userContext: state.ui.userContext,
  };
})(ExpertRequests);
export default ExpertRequests;
